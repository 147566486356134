import React from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon, ChevronUpIcon, FixedGridItem } from '../../../../ui';
import { IFooterLinkSection } from './types/IFooterLinkSection';
import { FooterSectionHeader } from './FooterSectionHeader';
import { FooterLinkList } from './FooterLinkList';
import { useTranslation } from '../../i18n/translate/useTranslation';
import Responsive from '../../../../ui/utils/Responsive';

export const StyledFooterLinkSection = styled(FixedGridItem)`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		padding-bottom: 16px;
	}
`;

interface IFooterSectionHeaderTitleProps {
	isOpen: boolean;
}

const FooterSectionHeaderTitle = styled(
	FooterSectionHeader,
)<IFooterSectionHeaderTitleProps>`
	svg {
		display: none;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;

		svg {
			display: block;
		}
	}
`;

interface IFooterLinkSectionProps extends IFooterLinkSection {
	isOpen: boolean;
	onClick: () => void;
}

export const FooterLinkSection = ({
	title,
	links,
	isOpen,
	onClick,
}: IFooterLinkSectionProps) => {
	const { t } = useTranslation();

	return (
		<StyledFooterLinkSection>
			<FooterSectionHeaderTitle
				isOpen={isOpen}
				onKeyPress={onClick}
				onClick={onClick}
				styling="h3"
			>
				<span>{title}</span>
				{isOpen ? (
					<ChevronUpIcon
						variation="inverted"
						aria-label={t('nav_item_chevronup_label')}
					/>
				) : (
					<ChevronDownIcon
						variation="inverted"
						aria-label={t('nav_item_chevrondown_label')}
					/>
				)}
			</FooterSectionHeaderTitle>
			<FooterLinkList links={links} isOpen={isOpen} />
		</StyledFooterLinkSection>
	);
};
