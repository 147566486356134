/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from '../shared/i18n/translate/useTranslation';

interface IDigicertSiteSealProps {
	className?: string;
}

const SCRIPT_URL = '//seal.digicert.com/seals/cascade/seal.min.js';

export const DigicertSiteSeal = ({ className }: IDigicertSiteSealProps) => {
	const sealRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	useEffect(() => {
		const digicertSitesealScript = document.createElement('script');
		digicertSitesealScript.defer = true;
		digicertSitesealScript.src = SCRIPT_URL;

		window.__dcid = window.__dcid || [];
		window.__dcid.push({
			cid: 'DigiCertClickID_j2YhRJGv',
			tag: 'j2YhRJGv',
			seal_format: 'dynamic',
		});

		document.body.appendChild(digicertSitesealScript);

		digicertSitesealScript.onload = () => {
			const objectElement = sealRef.current?.querySelector('object');
			if (objectElement) {
				objectElement.setAttribute('aria-label', t('digicert_site_seal'));
			}
		};

		return () => {
			document.body.removeChild(digicertSitesealScript);
		};
	}, []);

	return <div id="DigiCertClickID_j2YhRJGv" className={className} />;
};

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		__dcid: { cid: string; tag: string; seal_format: string }[];
	}
}
