import React, { Component } from 'react';
import { IFooterLinkSections } from './types/IFooterLinkSection';
import { FooterLinkSection } from './FooterLinkSection';

interface IFooterLinkSectionsProps {
	linkSections: IFooterLinkSections;
}

interface IFooterLinkSectionsState {
	currentSection: string | undefined;
}

export class FooterLinkSections extends Component<
	IFooterLinkSectionsProps,
	IFooterLinkSectionsState
> {
	public state = {
		currentSection: undefined,
	};

	private onClickSection = (key: string) => {
		this.setState(({ currentSection }) => ({
			currentSection: currentSection === key ? undefined : key,
		}));
	};

	public render() {
		const { linkSections } = this.props;
		const { currentSection } = this.state;

		return (
			<>
				{linkSections.map((linkSection) => (
					<FooterLinkSection
						{...linkSection}
						isOpen={currentSection === linkSection.key}
						onClick={() => this.onClickSection(linkSection.key)}
					/>
				))}
			</>
		);
	}
}
