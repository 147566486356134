import styled from '@emotion/styled';
import { H2, ITheme } from '../../../../ui';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';

export const FooterSectionHeader = styled(H2)<{}, ITheme>`
	color: ${themed(({ color }) => color.text.inverted)};
	margin-bottom: 24px;
	font-size: 24px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 16px;
	}
`;
