import React from 'react';
import styled from '@emotion/styled';
import {
	FacebookIcon,
	GridItem,
	InstagramIcon,
	ITheme,
	Link,
	VisuallyHidden,
	XLogoIcon,
	YouTubeIcon,
} from '../../../../ui';
import { FooterSectionHeader } from './FooterSectionHeader';
import { ITranslationFunction } from '../../i18n/translate';
import { useTranslation } from '../../i18n/translate/useTranslation';
import Responsive from '../../../../ui/utils/Responsive';

const FooterSocialLinksWrap = styled.div<{}, ITheme>`
	display: flex;

	${Link} {
		color: #fff;
		margin-right: 28px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	svg {
		width: 24px;
		height: unset;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 40px;

		svg {
			width: 36px;
			height: auto;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		justify-content: space-between;
	}
`;

export enum ISocialLinkItemPlatform {
	Facebook = 'Facebook',
	Instagram = 'Instagram',
	Twitter = 'Twitter',
	YouTube = 'YouTube',
}

export interface ISocialLinkItem {
	url: string;
	platform: ISocialLinkItemPlatform;
}

export interface IFooterSocialLinksProps {
	title: string;
	links: ISocialLinkItem[];
}

export const FooterSocialLinks = ({
	title,
	links,
}: IFooterSocialLinksProps) => {
	const { t } = useTranslation();

	return (
		<GridItem columns={[3, { tablet: 6, mobileXL: 12 }]}>
			<FooterSectionHeader styling="h3">{title}</FooterSectionHeader>
			<FooterSocialLinksWrap>
				{links.map((link) => renderLink(link, t))}
			</FooterSocialLinksWrap>
		</GridItem>
	);
};

function renderLink(
	{ platform, url }: ISocialLinkItem,
	t: ITranslationFunction,
) {
	const Icon = getIconForPlatform(platform);
	const label =
		platform === ISocialLinkItemPlatform.Twitter ? 'X@CLTAirport' : platform;

	return (
		<Link
			key={url}
			href={url}
			title={platform}
			target="_blank"
			rel="noopener"
			inverted
			hideExternalIndicator
		>
			<VisuallyHidden>
				{platform === ISocialLinkItemPlatform.YouTube
					? t('sr_only_footer_social_links_subscribe')
					: t('sr_only_footer_social_links_follow', { platform })}
			</VisuallyHidden>
			<Icon
				variation="inverted"
				aria-label={label}
				alt={t('new_window_aria_label')}
			/>
		</Link>
	);
}

function getIconForPlatform(platform: ISocialLinkItemPlatform) {
	switch (platform) {
		case 'Facebook':
			return FacebookIcon;

		case 'Instagram':
			return InstagramIcon;

		case 'Twitter':
			return XLogoIcon;

		case 'YouTube':
			return YouTubeIcon;

		default:
			throw new Error(`No icon available for platform: ${platform}`);
	}
}
