import { isExternalUrl } from '../../../../../ui';
import { getBaseUrl } from '../../../router/getBaseUrl';

export function getLinkPropsForUrl(url: string) {
	const props = {
		href: url,
	};

	const baseUrl = getBaseUrl();

	if (!baseUrl || !isExternalUrl(url, baseUrl)) {
		return props;
	}

	return {
		...props,
		target: '_blank',
		rel: 'noopener',
	};
}
