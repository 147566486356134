/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import {
	FixedGrid,
	FixedGridItem,
	Grid,
	ITheme,
	StyledArrowLinkButton,
} from '../../../../ui';
import { FooterAppLinks, IFooterAppLinksProps } from './FooterAppLinks';
import {
	FooterSocialLinks,
	IFooterSocialLinksProps,
} from './FooterSocialLinks';
import {
	FooterButtonSection,
	IFooterButtonSectionProps,
} from './FooterButtonSection';
import { FooterFinePrint, IFooterFinePrintProps } from './FooterFinePrint';
import { FooterLinkSections } from './FooterLinkSections';
import { StyledFooterLinkSection } from './FooterLinkSection';
import { useTranslation } from '../../i18n/translate/useTranslation';
import { IFooterLinkSections } from './types/IFooterLinkSection';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';

export const StyledFooter = styled.footer<{}, ITheme>`
	background: ${themed(({ color }) => color.brand)};
	color: ${themed(({ color }) => color.text.inverted)};
	padding: 40px 0;
	margin-top: 40px;

	h3 {
		color: ${themed(({ color }) => color.text.inverted)};
	}

	ul {
	}

	li {
		margin-bottom: 10px;
	}

	hr {
		margin: 40px 0;
		background: rgba(255, 255, 255, 0.5);
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		${StyledArrowLinkButton} {
			font-size: 16px;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		padding: 20px 0;

		hr {
			display: none;
		}
	}
`;

export interface IFooterProps {
	linkSections: IFooterLinkSections;
	appLinks: IFooterAppLinksProps;
	socialLinks: IFooterSocialLinksProps;
	buttonSection: IFooterButtonSectionProps;
	finePrint: IFooterFinePrintProps;
	children?: ReactNode;
}

export const Footer = ({
	linkSections,
	appLinks,
	socialLinks,
	buttonSection: buttons,
	finePrint,
}: IFooterProps) => {
	const { t } = useTranslation();

	return (
		<StyledFooter>
			<ContentWrap>
				<nav aria-label={t('footer_nav_label')}>
					<FixedGrid
						columns={[4, { tablet: 2, mobileXL: 1 }]}
						itemSelector={[StyledFooterLinkSection, FixedGridItem]}
					>
						<FooterLinkSections linkSections={linkSections} />
						<FooterAppLinks {...appLinks} />
					</FixedGrid>
				</nav>
				<hr />
				<Grid>
					<FooterSocialLinks {...socialLinks} />
					<FooterButtonSection {...buttons} />
				</Grid>
				<FooterFinePrint {...finePrint} />
			</ContentWrap>
		</StyledFooter>
	);
};
