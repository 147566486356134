import React from 'react';
import styled from '@emotion/styled';
import { ITheme, IUrlLinkItem, Link } from '../../../../ui';
import { withTranslate } from '../../i18n/translate';
import Responsive from '../../../../ui/utils/Responsive';

const FULL_YEAR = new Date().getFullYear();

const FooterFinePrintWrap = styled.div<{}, ITheme>`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-top: 40px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

const FooterFinePrintLinks = styled.div<{}, ITheme>`
	font-size: 16px;
	margin-right: 20px;

	${Link} {
		color: #fff;
		margin-left: 20px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		width: 100%;
		margin-right: 0;

		strong,
		${Link} {
			display: block;
		}

		${Link} {
			margin-top: 20px;
			margin-left: 0;
		}
	}
`;

export interface IFooterFinePrintProps {
	links: IUrlLinkItem[];
}

export const FooterFinePrint = withTranslate<IFooterFinePrintProps>(
	({ links, t }) => (
		<FooterFinePrintWrap>
			<FooterFinePrintLinks>
				<strong>{t('web_footer_copyright_text', { year: FULL_YEAR })}</strong>
				{links.map((link) => (
					<Link inverted key={link.key} href={link.url}>
						{link.title}
					</Link>
				))}
			</FooterFinePrintLinks>
		</FooterFinePrintWrap>
	),
);
