import React from 'react';
import styled from '@emotion/styled';
import { FixedGridItem, Link } from '../../../../ui';
import { FooterSectionHeader } from './FooterSectionHeader';
import appStoreIcon from '../../../../../assets/app-store.svg?reference';
import playStoreIcon from '../../../../../assets/play-store.svg?reference';
import { withTranslate } from '../../i18n/translate';
import { getLinkPropsForUrl } from '../../components/link/utils/getLinkPropsForUrl';
import { DigicertSiteSeal } from '../../../digicert-site-seal/DigicertSiteSeal';
import Responsive from '../../../../ui/utils/Responsive';

export interface IFooterAppLinksProps {
	title: string;
	appStore: string;
	playStore: string;
}

const FooterAppLinksWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: -10px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 40px;
	}
`;

const FooterAppLink = styled(Link)`
	margin-top: 10px;
	margin-right: 10px;

	// IE11 fix
	flex-grow: 1;

	&:last-of-type {
		margin-right: 0;
	}

	img {
		display: block;
		height: 42px;
		border: none;
	}
`;

export const FooterAppLinks = withTranslate<IFooterAppLinksProps>(
	({ title, appStore, playStore, t }) => (
		<FixedGridItem>
			<FooterSectionHeader styling="h3">{title}</FooterSectionHeader>
			<FooterAppLinksWrap>
				<FooterAppLink
					hideExternalIndicator
					inverted
					{...getLinkPropsForUrl(appStore)}
					rel="noopener"
					title={t('web_footer_app_store_download_title')}
				>
					<img
						alt={`${t(
							'web_footer_app_store_download_title',
						)} Opens in new window`}
						src={appStoreIcon}
					/>
				</FooterAppLink>
				<FooterAppLink
					hideExternalIndicator
					inverted
					{...getLinkPropsForUrl(playStore)}
					title={t('web_footer_play_store_download_title')}
				>
					<img
						alt={`${t(
							'web_footer_play_store_download_title',
						)} Opens in new window`}
						src={playStoreIcon}
					/>
				</FooterAppLink>
			</FooterAppLinksWrap>
			<StyledDigicertSiteSeal />
		</FixedGridItem>
	),
);

const StyledDigicertSiteSeal = styled(DigicertSiteSeal)`
	margin-top: 32px;
`;
