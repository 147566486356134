import React from 'react';
import styled from '@emotion/styled';
import { IUrlLinkItem, Link } from '../../../../ui';
import { getLinkPropsForUrl } from '../../components/link/utils/getLinkPropsForUrl';
import Responsive from '../../../../ui/utils/Responsive';

interface IStyledFooterLinkListProps {
	isOpen: boolean;
}

export const StyledFooterLinkList = styled.ul<IStyledFooterLinkListProps>`
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		margin-bottom: 10px;
		padding-left: 0;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		${({ isOpen }) => !isOpen && 'display: none;'}
		padding-top: 12px;
		font-size: 16px;

		${Link} {
			font-size: 16px;
		}
	}
`;

export interface IFooterLinkListProps {
	isOpen: boolean;
	links: IUrlLinkItem[];
}

export const FooterLinkList = ({ links, isOpen }: IFooterLinkListProps) => (
	<StyledFooterLinkList isOpen={isOpen}>
		{links.map((link) => (
			<li key={link.key}>
				<Link inverted {...getLinkPropsForUrl(link.url)}>
					{link.title}
				</Link>
			</li>
		))}
	</StyledFooterLinkList>
);
