import React from 'react';
import { ArrowLinkButton, GridItem, Row } from '../../../../ui';
import styled from '@emotion/styled';
import { FooterSectionHeader } from './FooterSectionHeader';

export interface IFooterButtonSectionProps {
	title: string;
	buttons: IFooterButton[];
}

export interface IFooterButton {
	title: string;
	link: string;
}

export const FooterButtonSection = ({
	title,
	buttons,
}: IFooterButtonSectionProps) => (
	<GridItem columns={[6, { mobileXL: 12 }]}>
		<FooterSectionHeader styling="h3">{title}</FooterSectionHeader>

		<Row columns={[2, { tablet: 1 }]}>
			{buttons.map((button) => (
				<StyledButton inverted href={button.link} key={button.link}>
					{button.title}
				</StyledButton>
			))}
		</Row>
	</GridItem>
);

const StyledButton = styled(ArrowLinkButton)`
	max-width: 264px;
	display: flex;
	justify-content: space-between;
`;
